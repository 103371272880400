var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"calendar",staticClass:"calendar-container"},[_c('div',{class:['fas', 'fa-chevron-left', 'arrow', _vm.shift > 0 ? 'blue' : ''],on:{"click":_vm.shiftLeft}}),_c('div',{staticClass:"calendar"},[_c('div',{staticClass:"days"},_vm._l((_vm.renderDays),function(day,index){return _c('div',{key:index},[_c('div',[_vm._v(_vm._s(_vm.dayName(day)))]),_c('div',[_vm._v(_vm._s(_vm.monthName(day) + " " + day.getDate()))])])}),0),_c('div',{staticClass:"body"},_vm._l((_vm.renderDays),function(day,index){return _c('div',{key:index},[(_vm.visit)?_c('div',_vm._l((_vm.renderDates[
            day.getDate() + ' ' + day.getMonth() + ' ' + day.getFullYear()
          ]),function(date,index){return _c('div',{key:index},_vm._l((_vm.calcRange(date.span)),function(range,index){return _c('div',{key:index,class:[
              _vm.dateFromSpan(date, index).getTime() > new Date().getTime()
                ? 'visit'
                : '',
              _vm.pickedDates.includes(_vm.dateFromSpan(date, index).getTime())
                ? 'picked'
                : '' ],on:{"click":function($event){return _vm.selectDate(date, index - 1)}}},[(_vm.dateFromSpan(date, index) > new Date())?_c('div',[_vm._v(" "+_vm._s(_vm.dateFromSpan(date, index).getHours() + ":" + _vm.dateFromSpan(date, index) .getMinutes() .toString() .padStart(2, "0"))+" ")]):_vm._e()])}),0)}),0):_vm._e()])}),0)]),_c('div',{staticClass:"fas fa-chevron-right arrow blue",on:{"click":_vm.shiftRight}})])}
var staticRenderFns = []

export { render, staticRenderFns }