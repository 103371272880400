
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import Calendar from "@/components/Calendar.vue";
import vSelect from "vue-select";
import { Patient, Session } from "@/interfaces/therapy";
import { get } from "@/api";
import "@/scss/vue-select.css";
import i18n from "@/i18n";
const pageKey = "patients";

@Component({
  components: {
    Calendar,
    vSelect,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class PatientsView extends Vue {
  input = "";
  visitTime = 30;
  sortType = this.$t("patients-view.most-recent");

  patients: Patient[] = [];
  sessions: null | Session[] = null;
  pickedDates = [];

  error = null;
  pending = false;

  getDataString(dateNumber: number) {
    const date = new Date(dateNumber);
    return date.toLocaleDateString("eu-eu");
  }

  async mounted() {
    await this.getSessions().then(() => {
      this.getPatients();
    });
  }

  async getSessions() {
    try {
      const { data } = await get.sessions();
      this.sessions = data;
      if (this.sessions)
        this.sessions = this.sessions.filter(
          (session: Session) => !session.cancelled
        );
    } catch (err) {
      console.log(err);
    }
  }

  get getVisitDates() {
    if (this.sessions && this.sessions.length !== 0 && this.pending) {
      return this.sessions
        .filter((session: Session) => session.accepted_by_therapist)
        .map((session: Session) => {
          return {
            span: session.span,
          };
        });
    } else if (this.sessions && this.sessions.length !== 0 && !this.pending) {
      return this.sessions
        .filter((session: Session) => !session.accepted_by_therapist)
        .map((session: Session) => {
          return {
            span: session.span,
          };
        });
    } else {
      return [];
    }
  }

  getPatients() {
    if (this.sessions) {
      const timestamp = new Date().getTime();
      this.sessions.forEach((session: Session) => {
        if (
          this.patients.filter((patient) => patient.id === session.user_id)
            .length === 0 ||
          this.patients.length === 0
        ) {
          this.patients.push({
            id: session.user_id,
            therapy: session.therapy,
            user: {
              first_name: session.user.first_name,
              last_name: session.user.last_name,
            },
            all_visits: [new Date(session.span.start).getTime()],
            last_visit:
              timestamp - new Date(session.span.start).getTime() > 0
                ? new Date(session.span.start).getTime()
                : 0,
            next_visit:
              timestamp - new Date(session.span.start).getTime() < 0
                ? new Date(session.span.start).getTime()
                : 0,
          });
        } else {
          const patient = this.patients.find(
            (patient) => patient.id === session.user_id
          );
          const sessionTimestamp = new Date(session.span.start).getTime();

          if (patient) {
            patient.all_visits.push(new Date(session.span.start).getTime());
            if (
              timestamp - sessionTimestamp > 0 &&
              (sessionTimestamp > patient.last_visit ||
                patient.last_visit === 0)
            ) {
              patient.last_visit = new Date(session.span.start).getTime();
            }
            if (
              timestamp - sessionTimestamp < 0 &&
              (sessionTimestamp < patient.next_visit ||
                patient.next_visit === 0)
            ) {
              patient.next_visit = new Date(session.span.start).getTime();
            }

            this.patients.splice(
              this.patients.map((item) => item.id).indexOf(session.user_id),
              1,
              patient
            );
          }
        }
      });
    }
  }

  get sortedPatients() {
    const sortStrategy = (a: Patient, b: Patient) => {
      switch (this.sortType) {
        case this.$t("patients-view.most-recent"): {
          return a.last_visit - b.last_visit;
        }
        case this.$t("patients-view.oldest"): {
          return b.last_visit - a.last_visit;
        }
        case this.$t("patients-view.asc"): {
          return a.user.first_name.localeCompare(b.user.first_name);
        }
        case this.$t("patients-view.desc"): {
          return b.user.first_name.localeCompare(a.user.first_name);
        }
        default:
          return 0;
      }
    };
    const filterStrategyInput = (patient: Patient) => {
      if (this.input !== "")
        return (
          patient.user.first_name
            .toLowerCase()
            .indexOf(this.input.toLowerCase()) != -1 ||
          patient.user.last_name
            .toLowerCase()
            .indexOf(this.input.toLowerCase()) != -1
        );
      return true;
    };
    // const filterStrategyChosenDay = (patient: Patient) => {
    //   if (this.pickedDates.length !== 0) {
    //     for (let i = 0; i < this.patients.length; i++) {
    //       if (patient.all_visits.indexOf(this.pickedDates[i]) !== -1)
    //         return true;
    //     }
    //     return false;
    //   }
    //   return true;
    // };
    return this.patients
      .sort(sortStrategy)
      .filter(filterStrategyInput);
      // .filter(filterStrategyChosenDay);
  }
}
