
import { Span } from "@/interfaces/misc";
import { VisitDate, VisitType } from "@/interfaces/visit";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Calendar extends Vue {
  @Prop({
    type: Array,
    default: function () {
      return [];
    },
  })
  dates!: VisitDate[];
  @Prop({
    type: Array,
    default: function () {
      return [];
    },
  })
  pickedDates!: number[];

  @Prop({ default: true })
  singleSelect!: boolean;

  @Prop()
  visit!: VisitType;

  shift = 0;
  today = new Date();

  columns = 1;

  mounted() {
    setTimeout(() => {
      this.columns = Math.floor(
        (this.$refs.calendar as HTMLElement).getBoundingClientRect().width / 82
      );
      if (this.pickedDates.length !== 0) {
        const tmp = this.pickedDates.sort((a: number, b: number) => a - b);
        const today = new Date();
        const firstVisit = new Date(tmp[0]);
        today.setHours(12, 0, 0);
        firstVisit.setHours(12, 0, 0);
        const diff =
          (firstVisit.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);
        this.shift = Math.floor(diff) / this.columns;
      }
    }, 10);
  }

  dayName(day: Date) {
    return Intl.DateTimeFormat(this.$i18n.locale, { weekday: "short" }).format(
      day
    );
  }

  monthName(day: Date) {
    return Intl.DateTimeFormat(this.$i18n.locale, { month: "short" }).format(
      day
    );
  }

  get renderDays() {
    const arr = [];
    const base = new Date(this.today);
    base.setDate(base.getDate() + this.columns * this.shift);
    for (let i = 0; i < this.columns; i++) {
      const temp = new Date(base);
      temp.setDate(temp.getDate() + i);
      arr.push(temp);
    }
    return arr;
  }

  get renderDates() {
    const obj: Record<string, any> = {};

    if (this.dates.length) {
      for (const date of this.dates) {
        const tmp = new Date(date.span.start);
        const string =
          tmp.getDate() + " " + tmp.getMonth() + " " + tmp.getFullYear();
        if (obj[string]) obj[string].push(date);
        else obj[string] = [date];
      }
    }
    return obj;
  }

  get shifted() {
    return this.shift > 0;
  }

  shiftLeft() {
    if (this.shift > 0) this.shift--;
  }

  shiftRight() {
    this.shift++;
  }

  selectDate(date: VisitDate, index: number) {
    if (!this.visit) return;
    const t = new Date(
      date.span.start + index * this.visit.duration * 1000 * 60
    ).getTime();
    this.$emit("date-clicked", t);
    if (this.singleSelect) {
      this.$emit("update:pickedDates", [t]);
    } else {
      if (this.pickedDates.includes(t))
        this.$emit(
          "update:pickedDates",
          this.pickedDates.filter((d) => d !== t)
        );
      else this.$emit("update:pickedDates", [...this.pickedDates, t]);
    }
  }

  calcRange(span: Span) {
    if (this.visit) {
      const duration = this.visit.duration === 0 ? 1 : this.visit.duration;
      return Math.floor(
        (span.end - span.start) / (duration * 1000 * 60)
      );
    }
    else return 0;

  }

  dateFromSpan(date: VisitDate, index: number) {
    if (this.visit)
      return new Date(
        date.span.start + (index - 1) * this.visit.duration * 1000 * 60
      );
  }
}
